.work__container {
  grid-template-columns: repeat(3, 330px);
  gap: 1.8rem;
  justify-content: center;
  padding-top: 1rem;
}

.work__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: 2rem;
}

.work__item {
  cursor: pointer;
  color: var(--title-color);
  padding: 0.25rem 0.75rem;
  font-weight: var(--font-medium);
  border-radius: 0.5rem;
}

.work__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 0.5rem;
}

.work__card h5 {
  text-align: center;
}

.work__img {
  border-radius: 0.5rem;
  margin-bottom: var(--mb-1);
}

.work__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
  text-align: center;
}

.work__button {
  color: var(--skin-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  cursor: pointer;
}

.work__button-icon {
  font-size: 1rem;
  transition: 0.3s;
}

.work__button:hover .work__button-icon {
  transform: translateX(0.25rem);
}

.work__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-modal);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.work__modal-content {
  width: 800px;
  position: relative;
  background-color: var(--container-color);
  padding: 4.5rem 2.5rem 2.5rem;
  border-radius: 1.5rem;
  grid-template-columns: repeat(2,1fr);
}

.work__modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  cursor: pointer;
}

.work__modal-title,
.work__modal-description {
  text-align: center;
}

.work__modal-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1);
}

.work__modal-description {
  font-size: var(--small-font-size);
  padding: 0 3.0rem;
  margin-bottom: var(--mb-2);
}

.work__modal-work {
  row-gap: 0.75rem;
}

.work__modal-service {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.work__modal-icon {
  color: var(--title-color);
  font-size: 1.1rem;
}

.work__modal-info li {
  font-size: var(--small-font-size);
  text-align: center;
}

.work__technlogies {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 1.5rem;
  margin: 1rem 0;
}

.work__category {
  margin: 0 0.5rem;
  padding: 0.5rem;
  border-radius: 10px;

  background-color: var(--title-color);
  color: var(--container-color);
}

.work__visit-button {
  margin: 1rem 3rem;
  padding: 1rem;
  border-radius: 10px;

  background-color: var(--title-color);
  color: var(--container-color);

}

.work__visit-button a {
  color: var(--container-color);
  text-decoration: none;
  font-weight: var(--font-medium);
}

.work__modal-subtitle {
  text-align: center;
  margin-bottom: 1rem;
  font-size: var(--small-font-size);

}

/* Active Modal */
.active-modal {
  opacity: 1;
  visibility: visible;
}

.active-work {
  background-color: var(--skin-color);
  color: var(--title-color);
}

/* Breakpoints */

/* For large devices */
@media screen and (max-width: 992px) {
  .work__container {
    grid-template-columns: repeat(2, 330px);
    gap: 1.5rem;
  }
}

/* For medium devices */
@media screen and (max-width: 576px) {
    .work__container {
        grid-template-columns: repeat(1, 400px);
        gap: 1.5rem;
      }
}

/* For small devices */
@media screen and (max-width: 350px) {
  :root {
    --big-font-size: 2.25rem;
  }

  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}
